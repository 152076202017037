@mixin wizard-defaults {
  @apply w-full;
  @apply h-full;
  @apply md:w-3/4;
  @apply md:h-3/4;
  @apply lg:w-240;
  @apply lg:h-176;
  @apply xl:w-288;
}

.wizard-responsive {
  @include wizard-defaults();
}

@mixin creation-dialog-defaults {
  @apply w-full;
  @apply h-full;
  @apply md:w-3/4;
  @apply md:h-3/4;
  @apply lg:w-1/2;
  @apply lg:h-1/2;
}

.creation-dialog-responsive {
  @include creation-dialog-defaults();
}
