@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'app/shared/material-components';
@import 'app/shared/ckeditor';

* {
  margin: 0;
  padding: 0;
}


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica', 'Arial', sans-serif;
}

// only way to eliminate MatDialog default-padding
.unset-default-padding > .mat-dialog-container {
  padding: 0;
}

// Needed for common-info
[type='email'] {
  border: unset;
  background: unset;
  padding: unset;
  font-size: unset;
  line-height: unset;
}

:root {
  --sidenav-width: 300px;
  --sidenav-collapsed-width: 20px;
}
