.mat-horizontal-stepper-wrapper {
  height: 100%;
}

.mat-stepper-previous {
  text-align: center;
}

.mat-paginator-outer-container {
  background-color: rgb(245 245 245);
  border-bottom-width: 2px;
}